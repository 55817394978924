import React from "react";
import g from "glamorous";

const mediaQueries = {
  phone: "@media only screen and (max-width: 991px)",
};

const Div = g.div({
  color: `#3c4d68`,
  borderRadius: `6px`,
  display: `flex`,
  flexDirection: `column`,
  alignItems: `stretch`,
  marginTop: `1em`,
});

const Input = g.input({
  backgroundColor: `#fcfcfc`,
  color: `#000000`,
  width: "100%",
  border: `none`,
  borderBottom: `2px solid black`,
  fontSize: `1em`,
  margin: `.7em 0 2em`,
  transition: `all .3s`,
  borderRadius: `0`,
  ":focus": {
    borderBottom: `2px solid #3c4d68`,
    outline: `none`,
    transition: `all .3s`,
  },
});

const InputHidden = g.input({
  display: `none`,
});

const Textarea = g.textarea({
  backgroundColor: `#fcfcfc`,
  color: `#3c4d68`,
  width: "96%",
  border: `.12em solid #3c4d68`,
  margin: `.8em 0 .5em`,
  fontSize: `.85em`,
  resize: `none`,
  fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'`,
  ":focus": {
    border: `.12em solid #3c4d68`,
    outline: `none`,
    transition: `all .3s`,
  },
  [mediaQueries.phone]: {
    width: "96%",
  },
});

const Button = g.input({
  backgroundColor: `#3c4d68`,
  border: `2px solid #3c4d68`,
  color: `white`,
  borderRadius: `5`,
  marginTop: `.8em`,
  marginLeft: `auto`,
  padding: `.3em .7em .45em`,
  fontSize: `1.1em`,
  fontWeight: `400`,
  letterSpacing: `.04em`,
  transition: `all .3s`,
  ":hover": {
    color: `#3c4d68`,
    backgroundColor: `#fcfcfc`,
    transition: `all .3s`,
  },
  ":focus": {
    outline: `none`,
    color: `#3c4d68`,
    backgroundColor: `#fcfcfc`,
    transition: `all .3s`,
  },
});

const Label = g.label({
  fontSize: `.85em`,
  fontWeight: `600`,
  letterSpacing: `.023em`,
});

export default ({ ...props }) => (
  <Div>
    <form action="https://formspree.io/f/mqkgdqrq" method="POST">
      <Label for="coordonnees">Votre email ou n° de téléphone</Label>
      <Input
        type="text"
        id="coordonnees"
        name="Coordonnees"
        aria-label="Votre email ou n° de téléphone"
        onKeyPress={(e) => {
          if (e.key === "Enter") e.preventDefault();
        }}
      />
      <Label for="intervention">L'intervention qui vous intéresse</Label>
      <Input
        type="textarea"
        id="intervention"
        name="Intervention"
        aria-label="L'intervention qui vous intéresse"
        onKeyPress={(e) => {
          if (e.key === "Enter") e.preventDefault();
        }}
      />
      <Label for="message">Votre message</Label>
      <Textarea
        type="textarea"
        id="message"
        name="Message"
        aria-label="Votre message"
        css={{
          marginTop: `1em`,
          height: `150px`,
          padding: `.5em`,
          width: `100%`,
          borderRadius: `6px`,
        }}
        wrap="hard"
      />
      <InputHidden
        type="textarea"
        id="page"
        value="Contact"
        name="Page"
        aria-label="Page du site consultée"
      />
      <input type="hidden" name="_language" value="fr" />
      <g.Div display={`flex`} justifyContent={`space-between`}>
        <Button type="submit" value="Envoyer" />
      </g.Div>
    </form>
  </Div>
);
