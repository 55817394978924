import React from "react";
import g from "glamorous";
import { css } from "glamor";
import { Link } from "gatsby";
import Layout from "../components/layout";
import Menu from "../components/Menu";
import Footer from "../components/Footer";
import ContactFormPage from "../components/ContactFormPage";
import { FaPhone } from "react-icons/fa";
import { Container, Row, Col } from "glamorous-grid";
import { Helmet } from "react-helmet";
import { FaHome } from "react-icons/fa";

export default function Template() {
  const Wrapper = g.main({
    maxWidth: `1000px`,
    margin: `0 auto 2.5em`,
  });

  const Title = g.h1({
    textAlign: `center`,
    fontSize: `38px`,
    color: `#3c4d68`,
    maxWidth: `700px`,
    margin: `1.5em auto 1em`,
    fontVariant: `none`,
    fontWeight: `600`,
    letterSpacing: `.01em`,
    lineHeight: `49px`,
    "@media(max-width: 767px)": {
      fontSize: `32px`,
      lineHeight: `32px`,
    },
  });

  const H2 = g.h2({
    fontSize: `25px`,
    fontWeight: `500`,
    lineHeight: `30px`,
    marginTop: `1em`,
    marginBottom: `1em`,
    "@media(max-width: 767px)": {
      fontSize: `20px`,
      lineHeight: `25px`,
    },
  });

  const cssLink = css({
    color: `#3c4d68`,
  });

  const Breadcrumb = g.ul({
    fontSize: `13px`,
    lineHeight: `20px`,
    marginLeft: `0 !important`,
    marginTop: `0`,
    marginBottom: `10px !important`,
    fontWeight: `500`,
    letterSpacing: `.015em`,
  });

  const BreadcrumbItem = g.li({
    display: `inline`,
    "&:first-of-type": {
      "&::before": {
        content: `none`,
      },
    },
    "&::before": {
      content: `/`,
      padding: `0 .5em`,
    },
  });

  const cssBreadcrumbLink = css({
    color: `#3c4d68`,
    borderBottom: `none`,
  });

  return (
    <Layout>
      <g.Div id="page-wrap" margin={`0 auto`} backgroundColor={`#fcfcfc`}>
        <Helmet>
          <title>Contactez-nous | Dr Kron, chirurgien esthétique à Paris</title>
          <meta
            name="description"
            content="Contactez-nous par téléphone ou par mail pour poser vos questions au secretariat concernant les interventions prartiqués au cabinet du Dr Kron."
          />
          <html lang="fr" />
          <link
            rel="alternate"
            hreflang="en"
            href="https://www.dr-kron.com/en/contact-us/"
          />
          <script type="application/ld+json">{` {
          "@context": "http://schema.org",
          "@type": "BreadcrumbList",
          "itemListElement": [{
          "@type": "ListItem",
          "position": 1,
          "name": "Contactez-nous",
          "item": "https://www.dr-kron.com/contactez-nous/"
          }]
              }
    `}</script>
        </Helmet>
        <Menu current="contact" pathEn="/en/contact-us/" />
        <Wrapper>
          <Container css={{ padding: `0 15px !important` }}>
            <Row>
              <Col>
                <Title>Contactez-nous</Title>
                <Breadcrumb>
                  <BreadcrumbItem>
                    <Link to="/" {...cssBreadcrumbLink} title="Page d'accueil">
                      <FaHome
                        css={{
                          fontSize: `1.2em`,
                          paddingBottom: `.2em`,
                          transform: `translateY(5px)`,
                        }}
                      />
                    </Link>
                  </BreadcrumbItem>

                  <BreadcrumbItem>Contactez-nous</BreadcrumbItem>
                </Breadcrumb>
                <p className="intro">
                  Pour toute demande de renseignements concernant les
                  interventions de chirurgie et de médecine esthétique
                  pratiquées au cabinet du Dr Cédric Kron ou pour prendre RDV
                  pour une consultation, n'hésitez pas à nous contacter.
                </p>

                <H2>Vous avez une question&nbsp;?</H2>
              </Col>
            </Row>

            <Row>
              <Col span={{ xs: 12 / 12 }}>
                <p>
                  Contactez le cabinet du Dr Cédric Kron par téléphone ou via le
                  formulaire de contact ci-dessous.
                  <br />
                  Nous vous répondrons dans les plus brefs délais.
                </p>
                <a href="tel:+33145628500" {...cssLink}>
                  <FaPhone
                    css={{
                      fontSize: `2em`,
                      color: `#3c4d68`,
                    }}
                  />{" "}
                  01 45 62 85 00
                </a>
                <ContactFormPage />
              </Col>
            </Row>
            <Row>
              <Col />
            </Row>
            <Row>
              <Col span={{ sm: 12 / 12, lg: 3 / 12 }}>
                <H2>Nos coordonnées</H2>
                <b>Cabinet de Dr Cédric Kron</b>
                <br />
                57, Avenue de Villiers
                <br />
                75017 Paris
                <br />
                <br />
                <b>Secrétariat</b> : 01 45 62 85 00
                <br /> <br />
                <b>Métro:</b>
                <br />
                Malesherbes (Ligne 3),
                <br />
                Monceaux (Ligne 2)
              </Col>
              <Col span={{ sm: 12 / 12, lg: 9 / 12 }}>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10494.725360729135!2d2.306251!3d48.883351!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xcba74be6729079a1!2sDr+C%C3%A9dric+Kron%2C+chirurgien+esth%C3%A9tique!5e0!3m2!1sfr!2sus!4v1532644775397"
                  width="100%"
                  height="300px"
                  frameBorder="0"
                  css={{ marginTop: `2.1em` }}
                  title="Le cabinet du Dr Kron sur Google Maps"
                />
              </Col>
            </Row>
            <Row>
              <Col span={{ sm: 12 / 12, md: 6 / 12 }}>
                <H2>Horaires d'ouvertures</H2>
                <ul>
                  <li>Lundi: 10h - 17h</li>
                  <li>Mardi : 10h - 19h</li>
                  <li>Mercredi : 8h- 16h</li>
                  <li>Jeudi : 10h - 18h30</li>
                  <li>Vendredi : 9h30 - 18h00</li>
                </ul>

                <p>
                  <b>Jours opératoires</b> : Lundi et jeudi
                </p>
              </Col>
              <Col span={{ sm: 12 / 12, md: 6 / 12 }}>
                <H2>Principaux tarifs</H2>
                <ul>
                  <li>1ère consultation esthétique : 120&nbsp;euros</li>
                  <li>1ère consultation conventionnée : 120&nbsp;euros</li>
                  <li>
                    <Link to="/visage/consultation-diagnostic/">
                      Diagnostic visage
                    </Link>{" "}
                    : Gratuit
                  </li>
                  <li>
                    <Link to="/silhouette/consultation-diagnostic/">
                      Diagnostic silhouette
                    </Link>{" "}
                    : Gratuit
                    <br />
                    <br />
                  </li>
                </ul>
                <Link to="/consultations/" {...cssLink}>
                  <strong>
                    > En savoir plus sur les différents type de consultations
                  </strong>
                </Link>
              </Col>
            </Row>
            <Row>
              <Col>
                <H2>Cliniques chirurgicales</H2>
                <p>
                  Le Docteur Kron exerce son activité de chirurgie esthétique et
                  de chirurgie plastique et reconstructrice conventionnée dans
                  des cliniques agréées en Chirurgie esthétique ou
                  conventionnées par la CPAM et les mutuelles. Son activité de
                  consultation et de médecine esthétique est effectuée à son
                  cabinet médical situé à Paris 17ème.
                </p>
              </Col>
            </Row>
            <Row>
              <Col span={{ xs: 12 / 12, sm: 6 / 12 }}>
                <b>Nouvelle Clinique d'Esthétique des Champs-Elysées</b>
                <br />
                Clinique agréée hors convention
                <br />
                61 avenue Franklin D. Roosevelt
                <br />
                75008 Paris
                <br />
                Anesthésiste : Dr A. Michon
                <br />
                <br />
              </Col>
              <Col span={{ xs: 12 / 12, sm: 6 / 12 }}></Col>
            </Row>
          </Container>
        </Wrapper>

        <Footer />
      </g.Div>
    </Layout>
  );
}
